import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as DeliveryCheck } from '../../svg/DeliveryCheck.svg';
import { theme } from '../Theme';

const UspsWrapper = styled('div')`
  width: 100%;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;

  .delivery-list {
    li {
      margin-left: 10px;
    }
  }
`;

export const Usps = ({ children }) => {
  return <UspsWrapper className="usps-wrapper">{children}</UspsWrapper>;
};

const UspItemWrapper = styled('div')`
  display: flex;
  svg {
    height: 18px;
    margin-right: 6px;
  }
  margin-right: 10px;
`;

export const UspItem = ({ text }) => {
  return (
    <UspItemWrapper className="usp-item">
      <DeliveryCheck />
      <p
        dangerouslySetInnerHTML={{
          __html: text?.value
        }}
      />
    </UspItemWrapper>
  );
};
