import React from 'react';
import { styled } from 'linaria/react';

const HtmlRowWrapper = styled('section')`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const HtmlRow = ({ children }) => {
  return <HtmlRowWrapper>{children}</HtmlRowWrapper>;
};
