import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { ProductCard } from '../CategoryPage/ProductCard';
import { SharedSwiper } from '../../r3-lib/components/layout/Swiper/SharedSwiper';
import { theme } from '../Theme';
import { Above, Below } from '@jetshop/ui/Breakpoints';

const ProductRowWrapper = styled('section')`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  max-width: 100%;

  [data-flight-image-container] {
    background: #f8f8f8;
  }
  [data-flight-image] {
    mix-blend-mode: darken;
  }

  .wrapper {
    width: 100%;
    max-width: calc(73rem + ${props => props.padding * 2}px);
    margin: auto;
    padding: ${props => props.padding}px;
  }

  &.fullWidth {
    max-width: 100%;
  }
  &.padding {
    padding: 0 40px;
    ${theme.below.lg} {
      padding: 0 20px;
    }
  }

  .shared-swiper {
    /* adjusts for product card padding */
    margin: 0 -10px;
  }
`;

export const ProductRow = ({ fullWidth, gap, padding, children, cartPage }) => {
  const desktopChildrenLength = 4;
  const mediumChildrenLength = children.length < 2.3 ? children.length : 2.3;
  const mobileChildrenLength = children.length < 1.5 ? children.length : 1.5;
  const desktopSlidesPerView = cartPage ?? desktopChildrenLength;
  const mediumSlidesPerView = cartPage ?? mediumChildrenLength;
  const mobileSlidesPerView = cartPage ? 1.5 : mobileChildrenLength;

  // const desktopSlidesPerView = 2.5;
  // const mediumSlidesPerView = children.length < 2.3 ? children.length : 2.3;
  // const mobileSlidesPerView = children.length < 1.5 ? children.length : 1.5;

  return (
    <Above breakpoint="lg">
      {aboveLg => {
        return (
          <Below breakpoint="sm">
            {belowMd => {
              return (
                <ProductRowWrapper
                  padding={padding?.value ?? 0}
                  gap={gap?.value ?? 0}
                  className={cx(fullWidth?.value && 'fullWidth')}
                >
                  <div className="wrapper">
                    <SharedSwiper
                      loop={children.length > 4 ? true : false}
                      slidesPerView={
                        aboveLg
                          ? desktopSlidesPerView
                          : belowMd
                          ? mobileSlidesPerView
                          : mediumSlidesPerView
                      }
                    >
                      {children}
                    </SharedSwiper>
                  </div>
                </ProductRowWrapper>
              );
            }}
          </Below>
        );
      }}
    </Above>
  );
};

export const ProductRowItem = ({ product }) => {
  return <ProductCard product={product?.value ?? product}></ProductCard>;
};
