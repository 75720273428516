import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../../components/Theme';

import Image from '@jetshop/ui/Image';
import { Breadcrumbs } from '../../ui/Breadcrumbs/Breadcrumbs';

const CategoryHeaderWrapper = styled('div')`
  width: 100%;
  text-align: center;

  .inner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > div {
      max-width: 50%;
    }

    .text-item {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      height: 100%;

      h1 {
        max-width: 70%;
        margin: auto;
        padding-bottom: 20px;
      }
      .breadcrumbs {
        max-width: 70%;
        margin: auto;
      }
    }

    ${theme.below.lg} {
      text-align: left;
      justify-content: flex-start;
      flex-direction: column;
      > div {
        max-width: 100%;
      }
      .text-item {
        padding: 30px 20px;
        h1 {
          max-width: 100%;
        }
        .breadcrumbs {
          max-width: 100%;
        }
        p {
          padding: 0;
        }
      }
    }
  }

  &.no-image {
    .inner-wrapper {
      > div {
        max-width: 73rem;
        margin: 3.5rem auto auto auto;
        padding-bottom: 1rem;
        text-align: center;
      }
    }

    .breadcrumbs {
      justify-content: center;
    }
    .text-item {
      justify-content: center;
    }

    ${theme.below.lg} {
      .text-item {
        margin: auto !important;
      }
    }
  }
`;

const CategoryHeaderImageItemWrapper = styled('div')`
  height: 400px;
  width: 100%;
  ::after {
    display: block;
    position: relative;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.195) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
    content: '';
    margin-top: -320px;
    height: 320px;
  }
  ${theme.below.md} {
    height: 217px;
    ::after {
      margin-top: -217px;
      height: 217px;
    }
  }
`;

const CategoryHeaderTextItemWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${theme.fonts.primary};

  h1 {
    font-weight: 700;
    line-height: 34px;
    font-size: 30px;
    margin-bottom: 10px;
    ${theme.below.lg} {
      font-size: 25px;
    }
  }

  p {
    margin-top: 20px;
    max-width: 70%;
    margin: auto;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    ${theme.below.lg} {
      max-width: 100%;
      padding: 0 20px;
    }
  }
`;

export const CategoryHeader = ({ image, title, text }) => {
  return (
    <CategoryHeaderWrapper className={!image?.value && 'no-image'}>
      <div className="inner-wrapper">
        {image?.value && (
          <CategoryHeaderImageItemWrapper className="image-item">
            <Image
              src={image?.value}
              fillAvailableSpace
              cover
              quality={80}
              critical={true}
              sizes={[1, 1, 1, 1, 1216]}
            />
          </CategoryHeaderImageItemWrapper>
        )}

        <CategoryHeaderTextItemWrapper className="text-item">
          <div>
            <Breadcrumbs />
            <h1>{title?.value}</h1>
            <p>{text?.value}</p>
          </div>
        </CategoryHeaderTextItemWrapper>
      </div>
    </CategoryHeaderWrapper>
  );
};
