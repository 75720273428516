import React from 'react';
import { default as BreadcrumbsNorce } from '@jetshop/ui/Breadcrumbs';
import { styled } from 'linaria/react';
import { theme } from '../../../../components/Theme';
import { useQuery } from '@apollo/react-hooks';
import breadcrumbsQuery from '../../../queries/Breadcrumbs.gql';
import { useLocation } from 'react-router';

const BreadcrumbsWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2px;
  font-family: ${theme.fonts.primary};

  ul {
    margin: 0;
    justify-content: flex-start;
  }
  li,
  a {
    color: #000 !important ;
    text-decoration: none;
    font-size: 12px;
    line-height: 20px;
  }
  li {
    ::after {
      content: '>';
    }
  }

  li:last-of-type {
    display: none;
    ::after {
      content: '';
    }
  }
  li:nth-last-child(2):after {
    display: none;
  }
`;

export const Breadcrumbs = () => {
  const location = useLocation();

  const { data } = useQuery(breadcrumbsQuery, {
    variables: {
      path: location?.pathname
    },
    errorPolicy: 'all'
  });

  const breadcrumbProps = {
    breadcrumbText: data?.route?.object?.breadcrumbText,
    parents: data?.route?.parents
  };

  return (
    <BreadcrumbsWrapper className="breadcrumbs">
      <BreadcrumbsNorce {...breadcrumbProps} />
    </BreadcrumbsWrapper>
  );
};
