import { styled } from 'linaria/react';
import React from 'react';
import { useIntl } from '@jetshop/intl';
import { useNewsletterSubscription } from '@jetshop/core/hooks/Subscriptions/useNewsletterSubscription';
import { theme } from '../Theme';

const FieldErrorWrapper = styled('div')`
  .column-title {
    font-family: ${theme.fonts.primary};
    font-weight: ${theme.fontWeights.semibold};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: ${theme.colors.white};
    margin-bottom: 10px;
  }
  ${theme.below.md} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  max-width: 270px;
  height: 38px;
  border-radius: ${theme.borderRadius};
  border: none;
  margin: 0;
  background: white;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  flex: 0 1 20rem;

  ${theme.below.lg} {
    max-width: 100%;
  }

  ${theme.above.md} {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  form,
  input {
    height: 100%;
    width: 100%;
  }

  input {
    border: none;
    background: transparent;
    outline: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.black};
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.02em;
  }
  input::placeholder {
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.black};
    font-weight: 500;
  }
  label {
    position: absolute;
    left: calc(0.5rem + 1px);
    top: -6px;
    background: white;
    padding: 0 0.5rem;
    color: gray;
  }
  button {
    color: ${theme.colors.black};
    background: ${theme.colors.secondary};
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    font-weight: ${theme.fontWeights.semibold};
    font-family: ${theme.fonts.primary};
    padding: 0 30px;
    border-radius: ${theme.borderRadius};
    right: 0;

    border: 0;
    outline: none;
    position: absolute;
    text-align: right;
    top: 0;
    height: 100%;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
    /* &:disabled {
      opacity: 0.5;
    } */
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #650e1b;
`;

function NewsletterField() {
  const {
    inputProps,
    submit,
    submitting,
    submitted,
    failed,
    errorStates: { alreadySubscribed, invalidEmail }
  } = useNewsletterSubscription({
    enabled: true
  });

  const t = useIntl();
  const disabled = inputProps.value.length === 0 || submitting;

  return (
    <FieldErrorWrapper>
      <label htmlFor="newsletter">
        <h2 className="column-title">{t('Newsletter')}</h2>
      </label>
      <Error>
        {alreadySubscribed &&
          t('You have already subscribed to the newsletter!')}
        {invalidEmail || failed
          ? t('Something went wrong. Please check your email and try again.')
          : null}
      </Error>
      <Wrapper>
        {submitted ? (
          <p data-testid="newsletter-subscription-success">
            {t('You are now subscribed')}
          </p>
        ) : (
          <form onSubmit={submit} disabled={disabled}>
            <input
              type="email"
              id="newsletter"
              placeholder={t('Enter email address')}
              style={{ paddingRight: submitting ? '8rem' : '4rem' }}
              data-testid="newsletter-subscription-input"
              {...inputProps}
            />
            <button
              type="submit"
              disabled={disabled}
              data-testid="newsletter-subscription-submit"
            >
              {submitting ? t('Submitting…') : t('Submit')}
            </button>
          </form>
        )}
      </Wrapper>
    </FieldErrorWrapper>
  );
}

export default NewsletterField;
