import { styled } from 'linaria/react';
import React from 'react';
import { useCookies } from 'react-cookie';
import { ReactComponent as Close } from '../../../assets/svg/Cross.svg';
import { SharedSwiper } from '../../layout/Swiper/SharedSwiper';

const CampaignBarContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CloseBtn = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: ${props => props.closeButtonColor};

  .icon-wrapper {
    margin-right: 11px;
    background: white;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 9px !important;
      height: auto;
      use {
        fill: ${props => props.closeButtonColor};
      }
    }
  }
`;

const SwiperContainer = styled('div')`
  position: relative;
  .slider-button {
    display: none;
  }
`;

export const CampaignBar = React.memo(
  ({ closeCampaignBarOption, closeButtonColor, children }) => {
    const [cookies, setCookie] = useCookies();
    const hideCampaignBar = cookies?.hideCampaignBar;
    const showCloseBtn = closeCampaignBarOption?.value === 'YES';

    const handleCloseClick = () => {
      setCookie('hideCampaignBar', true, [{ path: '/' }]);
    };

    const CloseButton = () =>
      showCloseBtn ? (
        <CloseBtn
          closeButtonColor={closeButtonColor?.value}
          onClick={() => {
            handleCloseClick();
          }}
        >
          <div className={'icon-wrapper'}>
            <Close />
          </div>
        </CloseBtn>
      ) : null;

    return (
      <>
        {!hideCampaignBar &&
          (children.length > 1 ? (
            <SwiperContainer id="campaign-bar">
              <CloseButton />
              <SharedSwiper shouldAutoplay={true} full={true}>
                {children}
              </SharedSwiper>
            </SwiperContainer>
          ) : (
            <CampaignBarContainer id="campaign-bar">
              <CloseButton />
              {children}
            </CampaignBarContainer>
          ))}
      </>
    );
  }
);
