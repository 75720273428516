import { styled } from 'linaria/react';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import useHeaderHeight from '../../r3-lib/hooks/useHeaderHeight';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { ReactComponent as HeartSVG } from '../../svg/HeartNew.svg';
import Badge from '../ui/Badge';
import { css } from 'linaria';
import { theme } from '../Theme';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  position: relative;
`;

const cartButtonWrapper = css`
  position: relative;
  .badge {
    position: absolute;
    right: -8px;
    top: -4px;
    pointer-events: none;
    color: white;
    background: ${theme.colors.primary};
    font-family: ${theme.fonts.primary};
    font-size: 11px;
    font-weight: 600;

    > div {
      margin-top: 1px;
    }
  }
`;

function FavouritesButton({ target, count, className, modals }) {
  return (
    <div className={cartButtonWrapper}>
      <Button
        className={className}
        data-testid="favourites-button"
        onClick={() => {
          modals && modals[0]?.hideTarget();
          if (target.isOpen) {
            target.hideTarget();
          } else {
            target.showTarget();
          }
        }}
      >
        <div className="badge-svg-wrapper" style={{ position: 'relative' }}>
          {count > 0 && <Badge text={count} />}
          <HeartSVG />
        </div>
      </Button>
    </div>
  );
}

function FavouritesButtonFlyout({ className, listId, modals }) {
  const { count } = useProductList(listId);
  const topPosition = useHeaderHeight();
  return (
    <DrawerTrigger
      preventOverflow={true}
      id="favourites-drawer"
      coverStyles={{
        top: topPosition,
        background: 'rgba(0,0,0,0.6)'
      }}
    >
      {drawer => (
        <FavouritesButton
          data-testid="favourites-button"
          id="favourites-button"
          className={className}
          target={drawer}
          count={count}
          modals={modals}
        />
      )}
    </DrawerTrigger>
  );
}

export default FavouritesButtonFlyout;
