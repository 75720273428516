import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Image from '@jetshop/ui/Image';
import { theme } from '../../../../components/Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { BoxContent } from '../BoxContent/BoxContent';
import { LinkEvaluator } from '../../layout/LinkEvaluator/LinkEvaluator';

const SideBySideWrapper = styled('div')`
  padding: 0;
  width: 100%;
  max-width: 71rem;
  margin: auto;
  display: flex;

  ${theme.below.lg} {
    height: 100%;
    flex-direction: column;
    br {
      display: none;
    }
  }

  &.reverse {
    flex-direction: row-reverse;
    ${theme.below.lg} {
      flex-direction: column;
    }
  }
  &.fullWidth {
    max-width: 100%;
  }
  &.padding {
    padding: 0 40px;
    ${theme.below.lg} {
      padding: 0 20px;
    }
  }

  .content-placer {
    /* padding: 40px; */
    ${theme.below.lg} {
      position: relative;
      padding: 30px 20px;
    }
  }
  .title {
    font-size: 44px;
    line-height: 44px;
    letter-spacing: 0.02em;
    ${theme.below.lg} {
      font-size: 28px;
      line-height: 36px;
      font-weight: 500;
    }
  }
  .text {
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.03em;
    ${theme.below.lg} {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .image-wrapper {
  }
`;

const ImageWrapper = styled('div')`
  width: 100%;
  height: 100%;
  min-width: ${props => props.ratio};
  ${theme.below.lg} {
    min-width: 100%;
  }
`;
const BoxWrapper = styled('div')`
  position: relative;
  width: 100%;
  background: ${props => props.backgroundcolor};
`;

export const SideBySide = ({
  padding,
  fullWidth,
  reverse,
  ratio,
  desktopImage,
  mobileImage,
  desktopImageRatio,
  mobileImageRatio,
  background,
  color,
  preTitle,
  title,
  boldTitle,
  text,
  link,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition
}) => {
  const imageSizes = [1, 1, 1, 300, 600];

  return (
    <SideBySideWrapper
      className={cx(
        'sidebyside',
        fullWidth?.value && 'fullWidth',
        padding?.value && 'padding',
        reverse?.value && 'reverse'
      )}
    >
      <>
        <ImageWrapper ratio={ratio?.value} className="image-wrapper">
          <LinkEvaluator link={link?.value}>
            <Above breakpoint="lg">
              {matches => (
                <Image
                  sizes={imageSizes}
                  src={matches ? desktopImage?.value : mobileImage?.value}
                  aspect={
                    matches ? desktopImageRatio?.value : mobileImageRatio?.value
                  }
                  cover
                />
              )}
            </Above>
          </LinkEvaluator>
        </ImageWrapper>
        <BoxWrapper color={color?.value} backgroundcolor={background?.value}>
          <BoxContent
            verticalPosition={verticalPosition}
            horizontalPosition={horizontalPosition}
            color={color}
            preTitle={preTitle}
            title={title}
            boldTitle={boldTitle}
            text={text}
            link={link}
            buttonTheme={buttonTheme}
            buttonText={buttonText}
            hasImage={
              desktopImage?.value?.length > 0 || mobileImage?.value?.length > 0
            }
          />
        </BoxWrapper>
      </>
    </SideBySideWrapper>
  );
};
