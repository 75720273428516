import t from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theme';
import CustomBadges from '../ui/CustomBadges';
import { Favourite } from '../ProductList/Favourite';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
  }

  [data-flight-image-container] {
    background: #f8f8f8;
  }
  [data-flight-image] {
    img {
      mix-blend-mode: darken;
      transform: translate3d(0, 0, 0);
    }
  }

  .product-card-detail {
    background: transparent;
    padding: 0.75em;
    line-height: 1.35;

    h3 {
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: ${theme.fonts.primary};
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    h4 {
      text-align: center;
      text-transform: uppercase;
      font-family: ${theme.fonts.primary};
      color: ${theme.colors.darkGray};
      font-weight: 400;
      font-size: 11px;
      line-height: 18px;
    }
  }
  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }
  .price-package-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${theme.fonts.primary};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    > div {
      display: flex;
    }

    .package-price-label {
      font-size: 12px;
      color: grey;
      margin-left: 5px;
    }

    .price,
    .new-price,
    .old-price {
      margin-top: 2px;
      margin-left: 5px;
    }
  }

  .new-price {
    color: ${theme.colors.red};
  }
  .old-price {
    color: ${theme.colors.darkGray};
  }

  .badge-top-right {
    top: 3em;
  }
`;

const FavouriteWrapper = styled('div')`
  position: absolute;
  top: 6px;
  right: 20px;
  z-index: 1;
`;

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'li',
  children,
  loadImageEagerly = false,
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  let badges = [...(product.badges || [])];
  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });
  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink
        product={product}
        {...linkProps}
        style={{
          margin: '0 0.5em 1em'
        }}
      >
        {hasImages ? (
          <div>
            <FavouriteWrapper className="favourite-wrapper">
              <Favourite product={product} />
            </FavouriteWrapper>
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product.images[0].alt}
              src={product.images[0].url}
              modifiedDate={product.images[0].modifiedDate}
              badges={<CustomBadges badges={badges} />}
              critical={loadImageEagerly}
            />
          </div>
        ) : (
          <Image src={transparentDataImg} />
        )}
        <section className="product-card-detail">
          <header>
            <h4 className="sub-name">{product.subName || '\u00A0'}</h4>
            <h3>{product.name}</h3>
          </header>
          <div className="price-package-wrapper">
            <Price
              price={product.price}
              previousPrice={product.previousPrice}
            />
          </div>
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
