import { useProductList } from '@jetshop/core/hooks/ProductList';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { Price } from '@jetshop/ui/Price';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CrossIcon } from '@jetshop/ui/svg/Cross.svg';
import { AddToCart } from './AddToCart';
import { SelectVariant } from './SelectVariant';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  padding: 1rem 0;

  > :first-child {
    max-width: 25%;
    width: 100%;
    border: none;
  }
  > :last-child {
    max-width: 70%;
    width: 100%;
    padding-left: 1rem;
  }
`;

const ProductName = styled('div')`
  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 86%;
  }
  a {
    text-decoration: none;
    color: ${theme.colors.black};
  }
  .attribute {
    display: block;
    font-family: ${theme.fonts.primary};
    font-size: 12px;
    line-height: 1.5;
  }
`;

const ProductDetail = styled('section')`
  position: relative;
  flex: 1 1 70%;
  padding: 0 0 0 10px;
`;

const ItemDetails = styled('div')`
  position: relative;
`;

const LinePrice = styled(Price)`
  font-weight: 600;
  text-align: left;

  .package-price-label {
    font-size: 12px;
    color: grey;
  }

  .new-price {
    color: ${theme.colors.red};
  }
  .old-price {
    color: ${theme.colors.darkGray};
  }
`;

const RemoveItem = styled('div')`
  position: absolute;
  right: -4px;
  top: 4px;
  text-align: right;
  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    border: none;
    background: #8f8f8f;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 8px;
    width: 8px;
  }
`;

export function Product({ product, listId, className = '' }) {
  // Use the variant's image for display
  const productForDisplay = { ...product };
  if (product.isVariant && product.variant.images) {
    productForDisplay.images = product.variant.images;
  }

  return (
    <>
      <Wrapper className={className}>
        <div>
          {product?.images?.length > 0 && (
            <Image
              sizes="5rem"
              src={product.images[0].url}
              alt={product.images[0].alt}
              quality={80}
            />
          )}
        </div>
        <ProductDetail>
          <ItemDetails>
            <RemoveItem>
              <RemoveFromList
                listId={listId}
                articleNumber={product.articleNumber}
                variant={product.variant}
              />
            </RemoveItem>

            <FlyoutTrigger id="favourites-drawer">
              {flyout => (
                <ProductName onClick={flyout.hideTarget}>
                  <Link to={product.primaryRoute.path}>
                    <h2 data-testid="item-name">{product.name}</h2>
                  </Link>
                </ProductName>
              )}
            </FlyoutTrigger>
          </ItemDetails>

          <LinePrice
            data-testid="item-price"
            price={product.price}
            previousPrice={product.previousPrice}
          />

          <div>
            {product.hasVariants && (
              <div className="select-variant">
                <SelectVariant listId={listId} product={product} />
              </div>
            )}
            {/* {product.validation.status === 'valid' ? (
              <AddToCart product={product} />
            ) : (
              <InvalidProduct validation={product.validation} />
            )} */}
          </div>
        </ProductDetail>
      </Wrapper>
    </>
  );
}

function RemoveFromList({ children, articleNumber, variant, listId }) {
  const { remove } = useProductList(listId);
  const variantArticleNumber = variant?.articleNumber;
  return (
    <button onClick={() => remove(articleNumber, { variantArticleNumber })}>
      <CrossIcon />
    </button>
  );
}

// function InvalidProduct({ validation }) {
//   const { status } = validation;
//   const reasonMessages = {
//     outOfStock: t('Out of stock'),
//     missingVariant: t('Select options'),
//     preOrder: t('Visit product page to add'),
//     configurations: t('Visit product page to add'),
//     package: t('Visit product page to add')
//   };
//   return (
//     <>
//       {status !== 'missingVariant' && (
//         <div className={invalidProductStyles}>
//           {reasonMessages[status] || t('Not available')}
//         </div>
//       )}
//     </>
//   );
// }

// const invalidProductStyles = css`
//   opacity: 0.3;
// `;
