// const test = ()
//  => {
//   switch (process.env.IMAGE_TAG_NAME) {
//     //Cykel&Fritid
//     case 'cykelfritid':
//       return (
//         process.env.IMAGE_TAG_NAME === 'cykelfritid' &&
//         require('./theme-cykelfritid.json'));
//     //OnlineCykel
//     case 'cykelfritid-onlinecykel':
//       return (module.exports =
//         process.env.IMAGE_TAG_NAME === 'cykelfritid' &&
//         require('./theme-cykelfritid-onlinecykel.json'));
//     //XLCykel
//     case 3:
//       return 847;
//     //Sandnas
//     default:
//       return 848;
//  }
//  }

const findChannelTheme = () => {
  switch (process.env.IMAGE_TAG_NAME) {
    //Cykel&Fritid
    case 'cykelfritid':
      console.log('cykelfritid', process.env.IMAGE_TAG_NAME);
      return require('./theme-cykelfritid.json');
    //OnlineCykel
    case 'cykelfritid-onlinecykel':
      console.log('onlinecykel', process.env.IMAGE_TAG_NAME);
      return require('./theme-cykelfritid-onlinecykel.json');
    //XLCykel
    case 'cykelfritid-xlcykel':
      console.log('xlcykel', process.env.IMAGE_TAG_NAME);
      return require('./theme-cykelfritid-xlcykel.json');
    //Sandnas
    case 'cykelfritid-sandnas':
      console.log('sandnas', process.env.IMAGE_TAG_NAME);
      return require('./theme-cykelfritid-sandnas.json');
    default:
      return require('./theme-cykelfritid.json');
  }
};

const channelTheme = findChannelTheme();
module.exports = channelTheme;
