import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { cx } from 'linaria';
import NewsletterField from '../../Newsletter/NewsletterField';

const NewsletterWrapper = styled('div')`
  max-width: 25%;
  display: flex;
  width: 100%;
  justify-content: flex-end;

  ${theme.below.lg} {
    max-width: 100%;
  }
  &.Onlinecykel {
    button {
      background: #c50a0a;
      color: #fff;
    }
  }
  &.XLCykel {
    button {
      background: #fd0;
    }
  }
  &.Sandnas {
    button {
      background: #e0b771;
    }
  }
`;

export const FooterNewsletter = ({ channel }) => {
  return (
    <NewsletterWrapper className={cx(channel)}>
      <div className="newsletter-container">
        <NewsletterField />
      </div>
    </NewsletterWrapper>
  );
};
