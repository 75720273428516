import homeCategoriesQuery from './components/Layout/Header/Categories/HomeCategoriesQuery.gql';
import CampaignBarQuery from './components/Layout/Header/CampaignBarQuery.gql';
// import LoginBackgroundQuery from './components/Auth/LoginBackgroundQuery.gql';
// import CategoryContentQuery from './components/ContentComponents/CategoryContentQuery.gql';

export const persistedQueries = [
  {
    query: homeCategoriesQuery,
    variables: { levels: 1 }
  },
  {
    query: CampaignBarQuery,
    variables: {
      id: 837
    }
  }
  // {
  //   query: CategoryContentQuery,
  //   variables: {
  //     id: 152
  //   }
  // }
];
