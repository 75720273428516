import React from 'react';
import { styled } from 'linaria/react';
import { LinkEvaluator } from '../../layout/LinkEvaluator/LinkEvaluator';
import { theme } from '../../../../components/Theme';

const FooterColumnLinkItemWrapper = styled('div')`
  a p {
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.white};
    font-size: 14px;
    line-height: 22px;
  }
`;

export const FooterColumnLinkItem = ({ linkName, link }) => {
  return (
    <FooterColumnLinkItemWrapper>
      <LinkEvaluator link={link?.value}>
        <p>{linkName?.value}</p>
      </LinkEvaluator>
    </FooterColumnLinkItemWrapper>
  );
};
