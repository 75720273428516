import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../../components/Theme';

const HtmlWrapper = styled('div')`
  margin-top: 10px;
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 1rem;
  }
  h1 {
    font-family: ${theme.fonts.primary};
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    letter-spacing: 0.04em;
  }
  h2 {
    line-height: 1.2;
  }
  p {
    font-family: ${theme.fonts.primary};
    font-size: 12px;
    line-height: 20px;
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.black};
    margin-bottom: 1rem;
  }
  ul {
    list-style-type: disc;
    padding-left: 1rem;
    li {
      font-family: ${theme.fonts.primary};
      font-size: 12px;
      line-height: 20px;
      font-weight: ${theme.fontWeights.regular};
      color: ${theme.colors.black};
    }
  }

  ol {
    list-style-type: numbers;
    padding-left: 1rem;
    li {
      font-family: ${theme.fonts.primary};
      font-size: 12px;
      line-height: 20px;
      font-weight: ${theme.fontWeights.regular};
      color: ${theme.colors.black};
    }
  }

  ${theme.below.lg} {
    max-width: 100%;
    padding: 0;
    margin: auto;
  }
`;

export const HtmlRowItem = ({ html }) => {
  return (
    <HtmlWrapper
      dangerouslySetInnerHTML={{
        __html: html?.value
      }}
    />
  );
};
