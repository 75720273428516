import React from 'react';
import { Link } from 'react-router-dom';
import { cx } from 'linaria';
import { isRelativeUrl } from '../../../helpers/functions/HelperCollection';
import { setVariableFromProp } from '../../../helpers/functions/HelperCollection';
import { linkEvaluatorStyle } from './LinkEvaluator.style';

/**
 * The LinkEvaluator function is a helper function that evaluates the link property of a component.
 * It can be used to render either an internal or external link depending on the value of the link property.
 *
 *
 * @param {string} link Set the link value for the link component
 * @param {function} linkClickCallback Run a function when the link is clicked
 * @param {string} className Pass a classname from the parent component
 * @param children Pass the children of the component
 *
 * @return <a/> <Link/> or <div>
 *
 * @docauthor Anders Zetterström @ R3
 */
export const LinkEvaluator = ({
  link,
  linkClickCallback,
  className,
  children
}) => {
  const linkValue = setVariableFromProp(link);

  if (linkValue) {
    return typeof linkValue === 'string' && !isRelativeUrl(linkValue) ? (
      <a
        href={linkValue}
        className={cx(
          linkEvaluatorStyle,
          className,
          'external-link link-evaluator'
        )}
        target={'_blank'}
        rel="noreferrer"
        onClick={e => {
          //additional function can be run when click on link (e.g closeNav)
          if (linkClickCallback) {
            linkClickCallback(e);
          }
        }}
      >
        {children}
      </a>
    ) : (
      <Link
        to={linkValue}
        className={cx(
          linkEvaluatorStyle,
          className,
          'internal-link link-evaluator'
        )}
        onClick={e => {
          //additional function can be run when click on link (e.g closeNav)
          if (linkClickCallback) {
            linkClickCallback(e);
          }
        }}
      >
        {children}
      </Link>
    );
  }

  return (
    <div
      className={cx(linkEvaluatorStyle, className, 'no-link link-evaluator')}
    >
      {children}
    </div>
  );
};

export const StyledLinkEvaluator = ({ link, linkClickCallback, children }) => {
  return (
    <LinkEvaluator
      link={link}
      linkClickCallback={linkClickCallback}
      className={linkEvaluatorStyle}
    >
      {children}
    </LinkEvaluator>
  );
};
