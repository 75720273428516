import React, { useState, useEffect } from 'react';
import { useScrollPosition } from '../hooks/useScrollPosition';

// TODO- this needs to be reworked , not working correctly
const HeaderHeightContext = React.createContext();

function HeaderHeightProvider({ children }) {
  const scroll = useScrollPosition();

  const [topPos, setTopPos] = useState(0);
  const docCheck = typeof document;
  useEffect(() => {
    let tempPos = 0;
    if (typeof document !== 'undefined') {
      const topBarHeight =
        document.getElementById('campaign-bar')?.clientHeight ?? 0;
      tempPos += document.getElementById('site-header')?.clientHeight ?? 0;
      tempPos += topBarHeight;
      if (scroll < topBarHeight) {
        tempPos -= scroll;
      } else if (scroll > topBarHeight) {
        tempPos -= topBarHeight;
      }
      setTopPos(tempPos);
    }
  }, [docCheck, scroll]);

  const value = { headerHeight: topPos };

  return (
    <HeaderHeightContext.Provider value={value}>
      {children}
    </HeaderHeightContext.Provider>
  );
}

export { HeaderHeightProvider, HeaderHeightContext };
