import JetshopText from '@jetshop/ui/JetshopText';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { FooterNewsletter } from './FooterNewsletter';
import DynamicCategoryRenderer from '../../../r3-lib/components/layout/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { footerComponents } from '../../ContentComponents/ContentComponents';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const FooterWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 5rem 2.5rem 1rem 2.5rem;
  margin: auto;
  background: #1d1d1d;

  .inner-wrapper {
    display: flex;
    max-width: 73rem;
    width: 100%;
    margin: auto;
    margin-bottom: 5rem;
    justify-content: space-between;
  }

  div {
    width: 100%;
  }
  .dynamic-category-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .contentcomponents-wrapper {
    display: flex;
    max-width: 75%;
    justify-content: space-between;
  }

  //MOBILE
  ${theme.below.lg} {
    padding: 40px 26px;

    .inner-wrapper {
      flex-direction: column;
      margin-bottom: 0;
    }
    .dynamic-category-wrapper {
      flex-direction: column;
    }
    .contentcomponents-wrapper {
      max-width: 100%;
    }
  }
`;

const PoweredByWrapper = styled('div')`
  width: 100%;
  text-align: center;
  p,
  a {
    color: ${theme.colors.white};
    text-decoration: none;
  }
`;

export const FooterContentComponents = React.memo(channelFooterId => {
  return (
    <>
      <DynamicCategoryRenderer
        categoryId={channelFooterId?.channelFooterId}
        rendererComponents={footerComponents}
      />
    </>
  );
});

export const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);

  const findChannelFooterId = () => {
    switch (selectedChannel?.id) {
      //Cykel&Fritid
      case 1:
        return 836;
      //OnlineCykel
      case 2:
        return 846;
      //XLCykel
      case 3:
        return 847;
      //Sandnas
      case 4:
        return 848;
      //default
      default:
        return 836;
    }
  };

  const channelFooterId = findChannelFooterId();

  return (
    <FooterWrapper>
      <div className="inner-wrapper">
        <div className="contentcomponents-wrapper">
          <FooterContentComponents channelFooterId={channelFooterId} />
        </div>
        <FooterNewsletter channel={selectedChannel?.name} />
      </div>
      <PoweredByWrapper>
        <JetshopText />
      </PoweredByWrapper>
    </FooterWrapper>
  );
};
