import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../../../components/Theme';

export const SeoInnerWrapper = styled('div')`
  text-align: left;
  font-family: ${theme.fonts.primary};
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 1rem;
  }
  h1 {
    line-height: 1.2;
  }
  h2 {
    font-size: 25px;
    line-height: 1.2;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.black};
    margin-bottom: 10px;
  }
  ul {
    list-style-type: disc;
    padding-left: 1rem;
    li {
      font-size: 14px;
      line-height: 1.5;
      font-weight: ${theme.fontWeights.regular};
      color: ${theme.colors.black};
    }
  }

  ${theme.below.lg} {
    h2 {
      font-size: 22px;
      line-height: 26px;
    }
    h3 {
      font-size: 16px;
      line-height: 22px;
    }
    h1,
    h2 {
      margin-bottom: 10px;
    }

    h3,
    h4 {
      margin-bottom: 5px;
    }
  }
`;

export const SeoRowItem = ({ title, html }) => {
  return (
    <SeoInnerWrapper>
      <h2>{title?.value}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: html?.value
        }}
      />
    </SeoInnerWrapper>
  );
};
