import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../../components/Theme';

const SeoWrapper = styled('div')`
  width: 100%;
  max-width: 700px;
  margin: 40px auto 8px auto;
  /* ${theme.below.lg} {
    padding: 0 18px;
  } */
`;

export const SeoRow = ({ children }) => {
  return <SeoWrapper>{children}</SeoWrapper>;
};
