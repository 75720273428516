import { styled } from 'linaria/react';
import { theme } from '../../Theme';

export default styled('main')`
  background: #ffffff;
  flex: 1 1 auto;
  padding-bottom: 2rem;

  ${theme.below.lg} {
    padding-bottom: 20px;
    overflow-x: hidden;
  }
`;
