import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { theme } from '../../../../components/Theme';

const CampaignBarItemContainer = styled('div')`
  background: ${props => props.backgroundcolor};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  width: 100vw;

  ${theme.below.lg} {
    min-height: 40px;
    text-align: center;
  }

  a,
  p {
    font-style: normal;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.08em;
    text-decoration: none;
    font-weight: 400;
    color: ${props => props.textcolor};
    ${theme.below.lg} {
      font-size: 14px;
    }
  }
`;

export const CampaignBarItem = ({ backgroundColor, link, text, textColor }) => {
  return (
    <CampaignBarItemContainer
      backgroundcolor={backgroundColor?.value}
      textcolor={textColor?.value}
      className="campaign-bar-item"
    >
      {link?.value?.length > 0 ? (
        <Link
          to={link?.value}
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      )}
    </CampaignBarItemContainer>
  );
};
