import React from 'react';
import { Above } from '@jetshop/ui/Breakpoints';
import { LinkEvaluator } from '../../layout/LinkEvaluator/LinkEvaluator';
import Image from '@jetshop/ui/Image';
import { theme } from '../../../../components/Theme';
import { styled } from 'linaria/react';
import { BoxContent } from '../BoxContent/BoxContent';

const ImageBoxRowWrapper = styled('div')`
  position: relative;
`;

const ImageWrapper = styled('div')`
  padding: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;

  ${theme.below.lg} {
    height: 100%;
    flex-direction: column;
    br {
      display: none;
    }
  }
`;

export const BoxRowImageItem = ({
  link,
  title,
  boldTitle,
  verticalPosition,
  horizontalPosition,
  color,
  buttonText,
  buttonTheme,
  desktopImage,
  mobileImage,
  desktopImageAspect,
  mobileImageAspect
}) => {
  const imageSizes = [1, 1, 1, 300, 600];

  return (
    <Above breakpoint="lg">
      {matches => (
        <ImageBoxRowWrapper className="item">
          <LinkEvaluator link={link}>
            <ImageWrapper>
              <Image
                sizes={imageSizes}
                src={matches ? desktopImage?.value : mobileImage?.value}
                aspect={
                  matches ? desktopImageAspect?.value : mobileImageAspect?.value
                }
                cover
              />
            </ImageWrapper>
            <BoxContent
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              color={color}
              boldTitle={boldTitle}
              title={title}
              buttonTheme={buttonTheme}
              buttonText={buttonText}
              hasImage={
                desktopImage?.value?.length > 0 ||
                mobileImage?.value?.length > 0
              }
            />
          </LinkEvaluator>
        </ImageBoxRowWrapper>
      )}
    </Above>
  );
};
