import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { ReactComponent as ChevronLeft } from '../../../assets/svg/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from '../../../assets/svg/ChevronRight.svg';
import { theme } from '../../../../components/Theme';

const SwiperButtonWrapper = styled('div')`
  /* height: 58px;
  width: 80px; */
  /* background: ${theme.colors.primary}; */
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 14px;
    width: 14px;
    color: ${theme.colors.white};
  }

  &.left {
    /* border-radius: 0 29px 29px 0; */

    svg {
      /* margin-right: 24px; */
    }
  }

  &.right {
    /* border-radius: 29px 0 0 29px; */
    svg {
      /* margin-left: 24px; */
    }
  }

  :hover,
  :active {
    opacity: 0.8;
  }
`;

export const SwiperButton = ({ direction, callback }) => {
  return (
    <SwiperButtonWrapper
      className={cx('slider-button', direction)}
      onClick={callback}
    >
      {direction === 'left' && <ChevronLeft />}
      {direction === 'right' && <ChevronRight />}
    </SwiperButtonWrapper>
  );
};
