import React from 'react';
import { theme } from '../../../../components/Theme';
import { cx } from 'linaria';
import { styled } from 'linaria/react';

const FooterLinksColumnWrapper = styled('div')`
  padding: 0 1rem 1rem 1rem;
  width: 100%;

  .column-title {
    font-family: ${theme.fonts.primary};
    font-weight: ${theme.fontWeights.semibold};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: ${theme.colors.white};
    margin-bottom: 10px;
  }

  ${theme.below.lg} {
    padding: 0;
    margin-bottom: 40px;
  }
`;

const ColumnLinksWrapper = styled('div')`
  &.row {
    display: flex;
    justify-content: flex-start;
  }
  a {
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.white};
    font-size: 12px;
    line-height: 20px;
  }
`;

export const FooterColumn = ({ title, row, children }) => {
  return (
    <FooterLinksColumnWrapper>
      <h2 className={cx('column-title', 'regular')}>{title?.value}</h2>
      <ColumnLinksWrapper className={row?.value === true && 'row'}>
        {children}
      </ColumnLinksWrapper>
    </FooterLinksColumnWrapper>
  );
};
