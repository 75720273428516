import React from 'react';
import Image from '@jetshop/ui/Image';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../../../components/Theme';
import { LinkEvaluator } from '../../layout/LinkEvaluator/LinkEvaluator';
import { Above } from '@jetshop/ui/Breakpoints';
import { BoxContent } from '../BoxContent/BoxContent';

export const HeroMaxWidth = styled('div')`
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 71rem;
  margin: auto;

  &.fullWidth {
    max-width: 100%;
  }
  &.padding {
    padding: 0 40px;
    ${theme.below.lg} {
      padding: 0 20px;
    }
  }

  ${theme.below.lg} {
    padding: 0;
    max-width: 100%;

    .titles-wrapper {
      max-width: 80%;
    }
  }

  :hover {
    .PRIMARY,
    .INVERTED_PRIMARY,
    .DARK,
    .LIGHT {
      opacity: 0.8 !important;
    }
  }
`;

export const Hero = ({
  fullWidth,
  padding,
  desktopImage,
  desktopImageRatio,
  mobileImage,
  mobileImageRatio,
  link,
  textColor,
  boldTitle,
  title,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition
}) => {
  const handleDummyButtonClick = () => {};

  const imageSizes = [1, 1, 1, 1, 1];
  // const imageSizes = [1, 1 / 2, 1 / 3, 600, 200];

  return (
    <Above breakpoint="lg">
      {matches => (
        <HeroMaxWidth
          className={cx(
            fullWidth?.value && 'fullWidth',
            padding?.value && 'padding'
          )}
        >
          <LinkEvaluator link={link}>
            <Image
              src={matches ? desktopImage?.value : mobileImage?.value}
              aspect={
                matches ? desktopImageRatio?.value : mobileImageRatio?.value
              }
              cover
              critical
              sizes={imageSizes}
            />
            <BoxContent
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              color={textColor}
              boldTitle={boldTitle}
              title={title}
              buttonTheme={buttonTheme}
              buttonText={buttonText}
              handleDummyButtonClick={handleDummyButtonClick}
              forType={'hero'}
              hasImage={
                desktopImage?.value?.value?.length > 0 ||
                mobileImage?.value?.value?.length > 0
              }
            />
          </LinkEvaluator>
        </HeroMaxWidth>
      )}
    </Above>
  );
};
