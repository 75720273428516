import { Helmet } from 'react-helmet-async';
import React, { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';

/**
 * The FaviconSelector function is a React component that renders the appropriate
 * favicon. It takes in one parameter:
 * faviconFolderPath which are a string that specify the path to the folder containing
 * all of our favicons.
 *
 * @param {string} faviconFolderPath the path to the favicon folder
 *
 * @return A helmet component that contains the following tags:
 *
 * @docauthor Anders Zetterström
 */
export const FaviconSelector = ({ faviconFolderPath }) => {
  const { selectedChannel } = useContext(ChannelContext);

  const channelThemeResolver = id => {
    switch (id) {
      case 1: //Cykel&Fritid
        return {
          folderName: 'cykelfritid',
          color: '#000000',
          title: 'Cykel&Fritid'
        };

      case 2: //OnlineCykel
        return {
          folderName: 'onlinecykel',
          color: '#000000',
          title: 'Onlinecykel'
        };

      case 3: //XLCykel
        return { folderName: 'xlcykel', color: '#fd0', title: 'XL Cykel' };

      case 4: //Sandnas
        return { folderName: 'sandnas', color: '#343434', title: 'Sandnas' };

      default:
        //default
        return { folderName: 'cykelfritid', color: '#000000', title: '🚴' };
    }
  };

  const channelTheme = channelThemeResolver(selectedChannel?.id);

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${faviconFolderPath}/${channelTheme?.folderName}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${faviconFolderPath}/${channelTheme?.folderName}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${faviconFolderPath}/${channelTheme?.folderName}/favicon-16x16.png`}
      />
      <link
        rel="manifest"
        href={`${faviconFolderPath}/${channelTheme?.folderName}/site.webmanifest`}
      />
      <link
        rel="mask-icon"
        href={`${faviconFolderPath}/${channelTheme?.folderName}/safari-pinned-tab.svg?`}
        color={channelTheme?.color}
      />
      <link
        rel="shortcut icon"
        href={`${faviconFolderPath}/${channelTheme?.folderName}/favicon.ico`}
      />
      <meta name="apple-mobile-web-app-title" content={channelTheme?.title} />
      <meta name="application-name" content={channelTheme?.title} />
      <meta name="msapplication-TileColor" content={channelTheme?.color} />
      <meta name="theme-color" content={channelTheme?.color} />
    </Helmet>
  );
};
