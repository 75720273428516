import CategoryLink from '@jetshop/ui/CategoryLink';
import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import AnimateHeight from 'react-animate-height';

// Limit the menu to our global MaxWidth
const MaxWidth = styled('div')`
  width: 100%;
  /* display: flex;
  justify-content: center; */
  padding: 30px 0;
  border-top: 1px solid ${theme.colors.border};
  display: flex;

  a {
    text-decoration: none;
    color: ${theme.colors.black};
    opacity: 0.8;
    display: block;
    padding: 1em 0.5em 1.25em 0.5em;
    :hover {
      opacity: 1;
      /* text-decoration: underline; */
    }
  }
`;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')`
  width: fit-content;
  margin: 0 auto;
  min-width: 100vw;
`;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  border-bottom: 1px solid ${theme.colors.border};
  border-width: 1px 0 1px 0;
  width: 100%;
`;

const Wrapper = styled('section')`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 100vw;
  background: ${theme.colors.white};
  overflow: hidden;
  z-index: 0;
  will-change: height;
  transition: height 0.3s ease-in-out;

  h2 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1em;
  }
`;

const SubCategoriesWrapper = styled('div')`
  column-count: 4;
  padding-bottom: 30px;
  /* flex: 1 1 25%; */
  margin: 0 40px;
  overflow: hidden;
  display: inline-block;
  max-width: 64rem;
  margin: auto;

  a {
    font-size: 14px;
    line-height: 26px;
    padding: 5px 0 !important;
  }
`;

const SubCategoryWrapper = styled('div')`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  /* max-width: 25%; */
  &.hide {
    display: none;
  }
`;

const StyledCategoryLink = styled(CategoryLink)`
  cursor: pointer;
  opacity: 0.7;
  margin: 4px 0;
  letter-spacing: 0.02em;
  :hover {
    opacity: 1;
  }
  &.level-2 {
    margin: 5px 0 0 0;
    padding: 0 !important;
    font-weight: 700;
    font-size: 14px;
  }
  &.level-3 {
    margin: 0 0 0 0;
    padding: 0 !important;
    font-weight: 400;
    font-size: 14px;
  }
`;

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <ContentFit>
          <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
            <MaxWidth>
              <SubCategoriesWrapper
                className="subcategories"
                // columncount={columns > 0 ? columns : ''}
              >
                {data?.categories?.map(category => {
                  return category?.subcategories?.map(subcategory => {
                    return (
                      <SubCategoryWrapper
                        key={subcategory?.id}
                        className={
                          category?.id === activeCategory?.id
                            ? 'active'
                            : 'hide'
                        }
                      >
                        <StyledCategoryLink
                          key={subcategory?.id}
                          className="level-2"
                          category={subcategory}
                          onClick={closeNav}
                        >
                          {subcategory?.name}
                        </StyledCategoryLink>
                        {subcategory?.subcategories &&
                          subcategory?.subcategories?.map(subSubCategory => {
                            return (
                              <StyledCategoryLink
                                key={subSubCategory?.id}
                                className="level-3"
                                category={subSubCategory}
                                onClick={closeNav}
                              >
                                {subSubCategory?.name}
                              </StyledCategoryLink>
                            );
                          })}
                      </SubCategoryWrapper>
                    );
                  });
                })}
              </SubCategoriesWrapper>
            </MaxWidth>
          </AnimateHeight>
        </ContentFit>
      </InnerWrapper>
    </Wrapper>
  );
};

export default SubMenuWrapper;
