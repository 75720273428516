//Components imported from local
import { Spacer } from './Spacer';
import { ProductRow, ProductRowItem } from './ProductRow';
import { Title } from './Title';
import { FAQ, FAQItem } from './FAQ';
// import { AccordionField, AccordionItem } from './AccordionField';

//UpsComponents imported from local
import { Usps, UspItem } from '../ProductPage/Usps';

//FooterComponents imported from Lib
import { FooterColumn } from '../../r3-lib/components/ce/FooterColumn/FooterColumn';
import { FooterColumnLinkItem } from '../../r3-lib/components/ce/FooterColumnLinkItem/FooterColumnLinkItem';
import { FooterColumnTextItem } from '../../r3-lib/components/ce/FooterColumnTextItem/FooterColumnTextItem';
import { FooterColumnSocialMediaItem } from '../../r3-lib/components/ce/FooterColumnSocialMediaItem/FooterColumnSocialMediaItem';

//seoComponents imported from Lib
import { SeoRow } from '../../r3-lib/components/ce/SeoRow/SeoRow';
import { SeoRowItem } from '../../r3-lib/components/ce/SeoRowItem/SeoRowItem';

//AllContentComponents imported from Lib
import { CampaignBar } from '../../r3-lib/components/ce/CampaignBar/CampaignBar';
import { CampaignBarItem } from '../../r3-lib/components/ce/CampaignBarItem/CampaignBarItem';
import { Hero } from '../../r3-lib/components/ce/Hero/Hero';
import { CategoryRow } from '../../r3-lib/components/ce/CategoryRow/CategoryRow';
import { CategoryRowItem } from '../../r3-lib/components/ce/CategoryRowItem/CategoryRowItem';
import { SideBySide } from '../../r3-lib/components/ce/SideBySide/SideBySide';
import { BoxRow } from '../../r3-lib/components/ce/BoxRow/BoxRow';
import { BoxRowImageItem } from '../../r3-lib/components/ce/BoxRowImageItem/BoxRowImageItem';
import { CategoryHeader } from '../../r3-lib/components/ce/CategoryHeader/CategoryHeader';
import { HtmlRow } from '../../r3-lib/components/ce/HtmlRow/HtmlRow';
import { HtmlRowItem } from '../../r3-lib/components/ce/HtmlRowItem/HtmlRowItem';

//<CE-compontent in uppercase> : <Project component>
export const allContentComponents = {
  CAMPAIGNBAR: CampaignBar,
  CAMPAIGNBARITEM: CampaignBarItem,
  SPACER: Spacer,
  HERO: Hero,
  CATEGORYROW: CategoryRow,
  CATEGORYROWITEM: CategoryRowItem,
  PRODUCTROW: ProductRow,
  PRODUCTROWITEM: ProductRowItem,
  TITLE: Title,
  SIDEBYSIDE: SideBySide,
  BOXROW: BoxRow,
  BOXROWIMAGEITEM: BoxRowImageItem,
  CATEGORYHEADER: CategoryHeader,
  FAQ: FAQ,
  FAQITEM: FAQItem,
  HTMLROW: HtmlRow,
  RAWHTML: HtmlRowItem,
  WYSIWYG: HtmlRowItem
  // ACCORDIONFIELD: AccordionField,
  // ACCORDIONITEM: AccordionItem
};

export const footerComponents = {
  FOOTERCOLUMN: FooterColumn,
  FOOTERCOLUMNLINKITEM: FooterColumnLinkItem,
  FOOTERCOLUMNTEXTITEM: FooterColumnTextItem,
  FOOTERCOLUMNSOCIALITEM: FooterColumnSocialMediaItem
};

export const uspComponents = {
  USPROW: Usps,
  USPITEM: UspItem
};

export const seoComponents = {
  SEO: SeoRow,
  SEOITEM: SeoRowItem
};
