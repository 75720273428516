import { styled } from 'linaria/react';
import { cx } from 'linaria';
import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../Theme';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import IconRow from './IconRow';

const DesktopHeaderWrapper = styled('div')`
  background: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const HeaderColumn = styled('div')`
  display: flex;
  align-items: center;
  &.center {
    flex: none;
  }
  &.right {
    width: 100%;
    max-width: 80%;
    justify-content: flex-end;
    svg {
      color: white;
    }
  }
  &.left {
    width: auto;
    margin-right: 60px;
    button {
      background: none;
    }
  }
`;
const LogoLink = styled(Link)`
  img,
  svg {
    height: 60px;
    width: auto;
  }
`;

const DesktopHeaderMenuWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const DesktopTopWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 86px;
  position: sticky;
  top: 0;
  z-index: 3;
  border-bottom: 1px solid ${theme.colors.border};

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 78rem;
    margin: auto;
  }

  &.Cykelfritid {
    background: #ffe50d;
    .right {
      svg {
        color: #333;
      }
      .badge {
        background: #333;
        color: #fff;
      }
    }
  }
  &.Onlinecykel {
    background: #000;
    .right {
      svg {
        color: #fff;
      }
      .badge {
        background: #fff;
        color: #000;
      }
    }
  }
  &.XLCykel {
    background: #fd0;
    .right {
      svg {
        color: #333;
      }
      .badge {
        background: #333;
        color: #fff;
      }
    }
  }
  &.Sandnas {
    background: #343434;
    .right {
      svg {
        color: #fff;
        background: #343434;
      }
      .badge {
        background: #e0b771;
        color: #343434;
      }
    }
  }
`;

export default function DesktopHeader({
  categories,
  searchOpen,
  setSearchOpen,
  selectedChannel,
  channelLogo
}) {
  return (
    <>
      <DesktopHeaderWrapper id="site-header">
        <DesktopTopWrapper className={cx(selectedChannel?.name)}>
          <div>
            <HeaderColumn className="left">
              <LogoLink to="/" className="logo-link">
                {channelLogo}
              </LogoLink>
            </HeaderColumn>
            <HeaderColumn className="right">
              <IconRow
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
                selectedChannel={selectedChannel}
              />
            </HeaderColumn>
          </div>
        </DesktopTopWrapper>
        <DesktopHeaderMenuWrapper>
          <CategoryMenu data={categories.data} />
        </DesktopHeaderMenuWrapper>
      </DesktopHeaderWrapper>
    </>
  );
}
