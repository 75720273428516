import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image';
import { theme } from '../../../../components/Theme';

const CategoryRowItemWrapper = styled('div')`
  width: 100%;
  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-top: 10px;
    text-align: center;
  }
`;

const CategoryRowLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  color: ${theme.colors.black};
`;

export const CategoryRowItem = ({ name, category, image }) => {
  const imageSizes = [1, 1 / 2, 1 / 2, 1, 600];
  return (
    <CategoryRowLink to={category?.value?.primaryRoute?.path}>
      <CategoryRowItemWrapper>
        <Image
          aspect="1:1"
          sizes={imageSizes}
          src={image?.value}
          alt={name?.value}
        />
        <h3>{name?.value}</h3>
      </CategoryRowItemWrapper>
    </CategoryRowLink>
  );
};
