import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { LinkEvaluator } from '../../layout/LinkEvaluator/LinkEvaluator';
import { theme } from '../../../../components/Theme';

const CTAWrapper = styled('div')`
  height: 40px;
  /* font-size: 16px; */
  letter-spacing: 0.1em;
  border-radius: ${theme.borderRadius};
  width: fit-content;
  padding: 0 27px;
  cursor: pointer;

  &.PRIMARY {
    background: ${theme.colors.secondary};

    a,
    div {
      color: ${theme.colors.blackish};
    }
  }

  &.INVERTED_PRIMARY {
    background: ${theme.colors.white};

    a,
    div {
      color: ${theme.colors.primary};
    }
  }

  &.DARK {
    background: ${theme.colors.black};

    a,
    div {
      color: ${theme.colors.white};
    }
  }

  &.LIGHT {
    background: ${theme.colors.white};

    a,
    div {
      color: ${theme.colors.black};
    }
  }

  :hover,
  :active {
    opacity: 0.8;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

const CenterButtonContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1em;
  font-size: 12px;
  line-height: 16px;
  font-weight: ${theme.fontWeights.semibold};
`;

export const CTA = ({ testid, link, className, clickCallback, children }) => {
  return (
    <CTAWrapper
      className={cx(className, 'cta-wrapper')}
      onClick={clickCallback}
      data-testid={testid || ''}
    >
      <LinkEvaluator link={link}>
        <CenterButtonContent>{children}</CenterButtonContent>
      </LinkEvaluator>
    </CTAWrapper>
  );
};
