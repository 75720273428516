import React, { useContext } from 'react';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { ReactComponent as MenuIcon } from '../../../svg/MenuMobile.svg';
import { ReactComponent as SearchIcon } from '../../../svg/SearchNew.svg';
import { ReactComponent as Cross } from '../../../r3-lib/assets/svg/Cross.svg';
import CartButton from '../../Cart/CartButton';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import useHeaderHeight from '../../../r3-lib/hooks/useHeaderHeight';
import { SearchField } from '@jetshop/ui/Search';
import autocompleteQuery from './AutocompleteQuery.gql';
import FavouriteButton from '../../ProductList/FavouriteCount';

const IconItems = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .icon-item {
    margin-left: 18px;
  }

  .favourites-cart {
    display: flex;
    justify-content: space-between;
  }

  //Mobile
  ${theme.below.lg} {
    justify-content: flex-end;
    .icon-item {
      margin-left: 10px;
    }
  }
`;

export const IconItem = styled('div')`
  button,
  svg {
    background: transparent;
    height: 21px;
    width: 19px;
    ${theme.below.lg} {
      width: 20px;
    }
  }
`;

export const SearchFieldWrapper = styled('div')`
  position: relative;
  display: flex;
  height: 40px;
  width: 326px;
  border-radius: 0;
  background: ${theme.colors.white};
  [data-flight-searchfield] {
    & > div {
      position: static;
    }
  }

  [data-flight-searchfield-flyout] {
    width: 100vw;
    top: 150%;
    left: -250px;
    border: none !important;
    border-top: 1px solid ${theme.colors.border} !important;
    border-bottom: 1px solid ${theme.colors.border} !important;

    > div {
      padding: 40px 60px;
      ${theme.below.lg} {
        padding: 20px 40px;
      }
    }

    h2 {
      font-family: ${theme.fonts.primary};
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      margin-bottom: 2px;
    }

    li,
    a {
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      line-height: 22px;
      margin-left: 10px;
      color: ${theme.colors.blackish};
      text-decoration: none;
      letter-spacing: 0.01em;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  input {
    outline: none;
  }

  svg {
    position: absolute;
    top: 0;
    left: 10px;
    height: 40px;
    width: 25px;
    background: transparent !important;
    color: ${theme.colors.blackish} !important;
    &:hover {
      cursor: pointer;
    }
  }

  input {
    width: 100%;
    border: none;
  }

  [data-flight-searchfield] {
    margin: 0 0 0 44px;
    width: 80%;
    > div {
      width: 100%;
    }
  }

  [data-flight-searchfield-cancel] {
    display: none;
  }

  //Mobile
  ${theme.below.lg} {
    width: 100%;

    [data-flight-searchfield-flyout] {
      left: 0px;
      top: 40px;
    }

    svg {
      bottom: 24px;
      left: 12px;
    }

    will-change: height;
    transition: height 0.3s ease-out;

    &.active {
      height: 40px;
      svg,
      input {
        height: 100%;
      }
      [data-flight-searchfield] {
        height: auto;
        opacity: 1;
      }
      border-bottom: 1px solid ${theme.colors.border};
    }

    &.inactive {
      height: 0;

      [data-flight-searchfield],
      input {
        opacity: 0;
      }
      svg,
      input {
        height: 0;
      }
    }
    &.search-active {
      height: 40px !important;
      [data-flight-searchfield],
      input {
        opacity: 1;
      }
      svg,
      input {
        height: 100%;
      }
      border-bottom: 1px solid ${theme.colors.border};
    }
  }
`;

export default function IconRow({ searchOpen, setSearchOpen }) {
  const { routes } = useShopConfig();
  const topPosition = useHeaderHeight();
  const { modals } = useContext(ModalContext);

  return (
    <ChannelContext.Consumer>
      {({ channels, selectedChannel, updateChannel }) => (
        <IconItems>
          <Above breakpoint="lg">
            {matches =>
              matches && (
                <>
                  <SearchFieldWrapper>
                    <SearchIcon
                      className="close-icon"
                      onClick={() => {
                        modals && modals[0]?.hideTarget();
                        setSearchOpen(!searchOpen);
                      }}
                    />

                    <SearchField
                      onCancel={() => null}
                      focusOnLoad={false}
                      placeholder="Sök produkt"
                      autocompleteQuery={autocompleteQuery}
                      onSubmit={() => setSearchOpen(false)}
                      onClick={() => setSearchOpen(false)}
                    />
                  </SearchFieldWrapper>
                </>
              )
            }
          </Above>
          <div className="favourites-cart">
            <IconItem className="icon-item">
              <FavouriteButton modals={modals} />
            </IconItem>

            <IconItem className="icon-item">
              <CartButton modals={modals} />
            </IconItem>
          </div>
          <Below breakpoint="lg">
            <IconItem className="icon-item">
              <DrawerTrigger
                preventOverflow={true}
                id="menu-drawer"
                coverStyles={{
                  top: topPosition,
                  background: 'rgba(0,0,0,0.6)'
                }}
              >
                {drawer => (
                  <button
                    data-testid="menu-button"
                    id="menu-button"
                    onClick={() => {
                      modals && modals[0]?.hideTarget();
                      if (drawer.isOpen) {
                        drawer.hideTarget();
                      } else {
                        drawer.showTarget();
                      }
                    }}
                  >
                    {drawer.isOpen ? <Cross /> : <MenuIcon />}
                  </button>
                )}
              </DrawerTrigger>
            </IconItem>
          </Below>
        </IconItems>
      )}
    </ChannelContext.Consumer>
  );
}
