import React from 'react';
import { styled } from 'linaria/react';
import { LinkEvaluator } from '../../layout/LinkEvaluator/LinkEvaluator';
import { theme } from '../../../../components/Theme';
import { ReactComponent as FacebookLogo } from '../../../assets/svg/Facebook.svg';
import { ReactComponent as InstagramLogo } from '../../../assets/svg/Instagram.svg';
import { ReactComponent as YoutubeLogo } from '../../../assets/svg/Youtube.svg';

const SocialColumnItem = styled('div')`
  width: auto !important;
  color: ${theme.colors.white};
  font-family: ${theme.fonts.primary};
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
  svg {
    height: 20px;
  }
`;

export const FooterColumnSocialMediaItem = ({ icon, link }) => {
  const iconSwitch = iconName => {
    switch (iconName) {
      case 'INSTAGRAM':
        return <InstagramLogo />;
      case 'FACEBOOK':
        return <FacebookLogo />;
      case 'YOUTUBE':
        return <YoutubeLogo />;

      default:
        return null;
    }
  };
  return (
    <SocialColumnItem>
      <LinkEvaluator link={link}>{iconSwitch(icon?.value)}</LinkEvaluator>
    </SocialColumnItem>
  );
};
