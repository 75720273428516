import React from 'react';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { useQuery } from 'react-apollo';
import { cx } from 'linaria';
//Temp. use local categorycontentquery > move to r3-lib
// import CategoryContentQuery from './CategoryContentQuery.gql';
import CategoryContentQuery from '../../../../components/ContentComponents/CategoryContentQuery.gql';
import {
  insertPropToComponentsChildren,
  insertPropToRootComponents
} from '../../../helpers/functions/HelperCollection';
import { dynamicCategoryRendererStyle } from './DynamicCategoryRenderer.style';
import { contentEditorComponents } from '../../ce/ContentEditorComponents';

/**
 * The DynamicCategoryRenderer function is a React component that renders the content of a category.
 * It takes in an array of items and returns them as HTML elements.
 * @param {number} categoryId id for fetching correct category data from the api
 * @param {object} rendererComponents Overrides the default components
 * @param {object} extraRootProps Add extra props to the root component of the category
 * @param {object} extraItemProps} Add extra props to the items
 *
 * @return A div width rendered components
 *
 * @docauthor Anders Zetterström @ R3
 */
const DynamicCategoryRenderer = ({
  categoryId,
  rendererComponents,
  extraRootProps,
  extraItemProps
}) => {
  // TODO - Handle queries when lib is package
  const { data, error } = useQuery(CategoryContentQuery, {
    variables: {
      id: categoryId
    }
  });
  if (!data || error) {
    return null;
  }

  const components = rendererComponents ?? contentEditorComponents;
  const items = data?.category?.data?.items;

  let renderItems = items;
  if (extraRootProps) {
    renderItems = insertPropToRootComponents(renderItems, extraRootProps);
  }

  if (extraItemProps) {
    renderItems = insertPropToComponentsChildren(renderItems, extraItemProps);
  }

  return (
    <div
      className={cx(dynamicCategoryRendererStyle, 'dynamic-category-wrapper')}
    >
      {items && <ContentRenderer items={renderItems} components={components} />}
    </div>
  );
};

export default DynamicCategoryRenderer;
