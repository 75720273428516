import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../../../components/Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { SharedSwiper } from '../../layout/Swiper/SharedSwiper';

const CategoryRowWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(71rem + ${props => props.padding * 2}px);
  margin: auto;
  padding: ${props => props.padding}px;

  &.fullWidth {
    max-width: 100%;
  }
  &.padding {
    padding: 0 40px;
    ${theme.below.lg} {
      padding: 0 20px;
    }
  }

  .items-wrapper {
    grid-gap: ${props => props.gap + 'px'};
  }

  ${theme.below.lg} {
    padding: 0;
  }
`;

const ItemsWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

export const CategoryRow = ({ title, fullWidth, gap, padding, children }) => {
  return (
    <CategoryRowWrapper
      padding={padding?.value ?? 0}
      gap={gap?.value ?? 0}
      className={cx(fullWidth?.value && 'fullWidth')}
    >
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <ItemsWrapper className="items-wrapper">{children}</ItemsWrapper>
          ) : (
            <SharedSwiper slidesPerView={1.5}>{children}</SharedSwiper>
          )
        }
      </Above>
    </CategoryRowWrapper>
  );
};
