import useHeaderHeight from '../../../../r3-lib/hooks/useHeaderHeight';
import t from '@jetshop/intl';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Caret } from '../../../../r3-lib/assets/svg/ChevronRight.svg';
import { theme } from '../../../Theme';

const Scroll = styled('div')`
  flex: 1 1 auto;
`;

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: ${props => props.topPosition}px;
  height: calc(100vh - ${props => props.topPosition}px);
  z-index: 99999;
  max-width: 100%;
  ${theme.below.lg} {
    max-width: 90%;
  }

  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }
`;
const MenuWrapper = styled('div')`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  overflow-y: ${props => (props.active ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transform: translateX(${props => (props.active ? 0 : -100)}%);
  transition: ${props => (props.active ? 0.4 : 0.2)}s ease-in-out;

  &.inactivemenu {
    display: none;
  }

  .categories {
    font-family: ${theme.fonts.primary};
    padding: 10px 20px;
  }

  svg {
    color: ${theme.colors.primary};
  }

  .custom {
    border-bottom: 1px solid ${theme.colors.border};
    span {
      opacity: 0.4;
    }
    svg {
      width: auto;
    }
  }

  &.submenu {
    a,
    span {
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
    }
    a,
    button {
      padding: 6px 0;
      height: auto;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
    }
  }

  button,
  a {
    svg,
    img {
      width: 1rem;
      object-fit: contain;
    }
    span {
      font-size: 16px;
      line-height: 20px;
      svg,
      img {
        width: 1.5rem;
        max-height: 1rem;
        margin-right: 0.5rem;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 10px 0;
    text-align: left;

    color: ${theme.colors.black};
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    width: 100%;
    span {
      display: flex;
      align-items: center;
    }
    &.red {
      color: red;
    }

    &:focus {
      outline: none;
    }
    &:active {
      background: #f9f9f9;
    }
  }
  .backbutton {
    margin-left: -10px;
    border: 0;
    svg {
      transform: rotate(-180deg);
      height: 12px;
      margin-right: 2px;
      margin-bottom: 2px;
    }
    span {
      justify-content: flex-end;
    }
  }
`;

const MenuHeader = styled('div')`
  max-width: 332px;
  display: flex;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid ${theme.colors.border};
  flex-direction: row;
  justify-content: space-between;
  padding-top: 26px;
  padding-bottom: 6px;
  margin: 0 20px;

  a {
    border: none;
    svg {
      width: auto;
    }
  }

  span {
    width: 100%;
    font-size: 16px !important;
    line-height: 38px !important;
  }

  &.empty-header {
    border-bottom: none;
    min-height: auto;
  }
  .small {
    font-size: 12px !important;
    line-height: 16px !important;
  }
`;

const MenuLevel = ({
  categories,
  active,
  goBack = () => {},
  parent = null,
  subData,
  ...rest
}) => {
  const [activeId, setActiveId] = useState(null);
  const backHandler = () => {
    setActiveId(null);
  };

  useEffect(() => {
    if (!rest.isOpen && activeId) {
      //To avoid flicker on close
      setTimeout(() => {
        setActiveId(null);
      }, 300);
    }
  }, [rest.isOpen, activeId]);

  return (
    <>
      <>
        <MenuWrapper
          active={active}
          // className={parent ? 'submenu' : 'toplevel'}
          className={cx(
            parent ? 'submenu' : 'toplevel',
            active ? 'activemenu' : 'inactivemenu'
          )}
        >
          <MenuHeader className={!parent && 'empty-header'}>
            {parent ? (
              <>
                <span>{parent?.name}</span>
                <button onClick={goBack} className="backbutton">
                  <span className="small">
                    <Caret />
                    {parent.parent ? <>{t('Back')}</> : t('Back')}
                  </span>
                </button>
              </>
            ) : null}
          </MenuHeader>
          <div className="categories">
            {parent && (
              <Link onClick={rest.close} to={parent?.primaryRoute?.path}>
                <span>Alla {parent?.name}</span>
              </Link>
            )}
            {categories.map(cat =>
              cat.hasSubcategories && cat?.subcategories ? (
                <button
                  key={`btn-${cat.id}`}
                  onClick={() => setActiveId(cat.id)}
                >
                  <span className={cat?.parent?.id ? 'caps' : ''}>
                    {cat.name}
                  </span>
                  <Caret />
                </button>
              ) : (
                <React.Fragment key={cat.id}>
                  {cat?.name?.toLowerCase() !== 'menu content' && (
                    <Link
                      onClick={rest.close}
                      to={cat.primaryRoute.path}
                      key={`link-${cat.id}`}
                    >
                      <span className={cx(!parent ? 'caps' : '')}>
                        {cat.name}
                      </span>
                    </Link>
                  )}
                </React.Fragment>
              )
            )}
          </div>
        </MenuWrapper>

        {categories?.map(cat =>
          cat.hasSubcategories && cat?.subcategories ? (
            <MenuLevel
              key={`cat-${cat.id}`}
              {...rest}
              parent={{ ...cat, parent: parent }}
              goBack={backHandler}
              categories={cat.subcategories}
              active={cat.id === activeId}
            />
          ) : null
        )}
      </>
    </>
  );
};

export const PositionedDrawer = ({ isOpen, size = 332, children, left }) => {
  const topPosition = useHeaderHeight();

  return (
    <NewDrawer
      topPosition={topPosition}
      isOpen={isOpen}
      size={size}
      className={left ? 'left' : ''}
    >
      {children}
    </NewDrawer>
  );
};

export default function FlyoutMenu({ categoryData, size = 332, left = false }) {
  if (!(categoryData && categoryData.categories)) return null;
  return (
    <>
      <DrawerTarget id="menu-drawer">
        {drawer => (
          <PositionedDrawer isOpen={drawer.isOpen} size={size} left={left}>
            <Scroll>
              <MenuLevel
                isOpen={drawer.isOpen}
                close={drawer.hideTarget}
                categories={categoryData.categories}
                active={true}
              />
            </Scroll>
          </PositionedDrawer>
        )}
      </DrawerTarget>
    </>
  );
}
