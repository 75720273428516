import React from 'react';
import { styled } from 'linaria/react';
import { ContentPlacer } from '../ContentPlacer/ContentPlacer';
import { cx, css } from 'linaria';
import { CTA } from '../../ui/CTA/CTA';
import { theme } from '../../../../components/Theme';
import { Link } from 'react-router-dom';

const ContentWrapper = styled('div')`
  color: ${props => props.color};
  width: 100%;

  a {
    text-decoration: none;
  }

  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 0.05em;
    ${theme.below.lg} {
      font-size: 40px;
      line-height: 44px;
    }
  }
  h3 {
    font-family: ${theme.fonts.primary};
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
    ${theme.below.lg} {
      font-size: 18px;
      line-height: 18px;
      font-weight: 400;
    }
  }
  .text {
    margin-top: 30px;
    letter-spacing: 0.03em;

    p {
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 10px;
      letter-spacing: 0.02em;
    }
  }

  &.hero {
    h2 {
      font-family: ${theme.fonts.primary};
      font-size: 60px;
      line-height: 80px;
      letter-spacing: 0.05em;
      ${theme.below.lg} {
        font-size: 40px;
        line-height: 44px;
      }
    }
  }
`;

const heroButtonStyle = css`
  margin-top: 30px;
  padding: 0 27px;
`;

const boxButtonStyle = css`
  margin-top: 23px;
  ${theme.below.lg} {
    margin-top: 2rem;
  }
`;

export const BoxContent = ({
  verticalPosition,
  horizontalPosition,
  color,
  boldTitle,
  preTitle,
  title,
  text,
  buttonText,
  buttonTheme,
  handleDummyButtonClick,
  link,
  forType
}) => {
  return (
    <ContentPlacer
      verticalPosition={verticalPosition?.value ?? verticalPosition}
      horizontalPosition={horizontalPosition?.value ?? horizontalPosition}
    >
      <ContentWrapper color={color?.value ?? '#fff'} className={forType}>
        <div className="titles-wrapper">
          {preTitle?.value && (
            <h3
              dangerouslySetInnerHTML={{
                __html: preTitle?.value
              }}
            />
          )}
          {title?.value && (
            <h2
              style={
                boldTitle?.value === true
                  ? { fontWeight: '700' }
                  : { fontWeight: '400' }
              }
              dangerouslySetInnerHTML={{
                __html: title?.value
              }}
            />
          )}
        </div>
        {text?.value && (
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: text?.value
            }}
          />
        )}
        {buttonText?.value &&
          (link?.value ? (
            <Link to={link?.value}>
              <CTA
                className={cx(
                  buttonTheme?.value,
                  forType === 'hero' ? heroButtonStyle : boxButtonStyle
                )}
                clickCallback={handleDummyButtonClick}
              >
                {buttonText?.value}
              </CTA>
            </Link>
          ) : (
            <CTA
              className={cx(
                buttonTheme?.value,
                forType === 'hero' ? heroButtonStyle : boxButtonStyle
              )}
              clickCallback={handleDummyButtonClick}
            >
              {buttonText?.value}
            </CTA>
          ))}
      </ContentWrapper>
    </ContentPlacer>
  );
};
