import React from 'react';
import { styled } from 'linaria/react';

const TitleWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
    font-size: 25px;
    line-height: 34px;
    font-weight: 700;
  }
`;

export const Title = ({ text }) => {
  return (
    <TitleWrapper className="titlewrapper">
      <h2>{text?.value}</h2>
    </TitleWrapper>
  );
};
