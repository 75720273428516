import { useNotification } from '@jetshop/core/components/Notifications';
import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import addMultipleToCartMutation from '../Cart/queries/addMultipleToCart.gql';
import cartQuery from '../Cart/queries/cartQuery.gql';
import useHeaderHeight from '../../r3-lib/hooks/useHeaderHeight';
import { theme } from '../Theme';
import { baseStyles } from '../ui/Button';
import { Product } from './Product';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { cx } from 'linaria';

const DrawerWrapper = styled('div')`
  // Flyout
  position: relative;
  z-index: 99999;
  > div {
    background: #f7f7f7;
    top: ${props => props.topPosition}px;
    border-top: 1px solid ${theme.colors.border};
  }
`;

const Flyout = styled('div')`
  background: #f7f7f7;
  padding-bottom: 150px;
  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    width: 22rem;
    min-width: 19rem;
    max-width: 100%;
  }

  .empty-title {
    font-family: ${theme.fonts.primary};
    font-weight: 600;
    text-align: left;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 20px;
    + p {
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      line-height: 22px;
      text-align: left;
    }
  }
`;

const CartItems = styled('section')`
  background: white;
  padding: 0 20px;
  li {
    border-top: 1px solid ${theme.colors.border};
  }
  li:first-of-type {
    border: none;
  }
  .favourite-wrapper {
    display: none;
  }
`;

const Summary = styled('section')`
  padding: 20px;
`;
const AddToCartButton = styled('button')`
  ${baseStyles};
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  appearance: none;
  background: ${theme.colors.secondary};
  color: ${theme.colors.blackish};
  border-radius: ${theme.borderRadius};
  border: 0;
  font-family: ${theme.fonts.primary};
  font-weight: 500;

  :hover {
    opacity: 0.9;
  }

  &.Onlinecykel {
    background: #000;
    color: #fff;
  }
  &.XLCykel {
    background: #fd0;
    color: ${theme.colors.blackish};
  }
  &.Sandnas {
    background: #e0b771;
    color: ${theme.colors.blackish};
  }
`;

const RemoveButton = styled('button')`
  ${baseStyles};
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  appearance: none;
  background: ${theme.colors.blackish};
  color: white;
  border-radius: ${theme.borderRadius};
  border: 0;
  font-family: ${theme.fonts.primary};
  font-weight: 500;

  :hover {
    opacity: 0.9;
  }
`;

const addAllToastStyles = css`
  background: white;
  color: ${theme.colors.black};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;

const FavouritesFlyoutView = ({ modal, ...rest }) => {
  const listId = null;

  const { products, loading } = useProductListItems(listId);
  const [trigger] = useNotification();

  const validItems = getItemsForAddToCart(products);
  const validItemCount = validItems.length;

  const { selectedChannel } = useContext(ChannelContext);

  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        trigger(
          <AddAllSuccessToast>
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>
        );
      }
    }
  );

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  return (
    <>
      <DrawerTrigger preventOverflow={true} id="favourites-drawer">
        {drawer => {
          if (noProductsInList) {
            return (
              <Flyout {...rest}>
                <div style={{ padding: '2em', background: 'white' }}>
                  <h2 className="empty-title">{t('Saved items')}</h2>
                  <p>{t('There are no products in your saved items list.')}</p>
                </div>
                <Summary>
                  <RemoveButton
                    className="with-border"
                    onClick={modal.hideTarget}
                  >
                    {t('Continue Shopping')}
                  </RemoveButton>
                </Summary>
              </Flyout>
            );
          }

          if (loadingInitialRender) {
            return (
              <Flyout {...rest}>
                <div style={{ padding: '2em', background: 'white' }}>
                  <h2 className="empty-title">{t('Saved items')}</h2>
                  <p>{t('Loading your saved items…')}</p>
                </div>
                <Summary>
                  <RemoveButton
                    className="with-border"
                    onClick={modal.hideTarget}
                  >
                    {t('Continue Shopping')}
                  </RemoveButton>
                </Summary>
              </Flyout>
            );
          }

          return (
            <Flyout {...rest}>
              <CartItems>
                <ul className="product-grid">
                  {products.map((product, index) => {
                    return (
                      <li
                        key={
                          product.variant?.articleNumber ||
                          product.articleNumber
                        }
                        data-valid={product.validation.status}
                      >
                        <Product listId={listId} product={product} />
                      </li>
                    );
                  })}
                </ul>
              </CartItems>

              <Summary>
                <AddToCartButton
                  onClick={addToCart}
                  className={cx(selectedChannel?.name, 'add-all')}
                  disabled={validItemCount === 0}
                >
                  {validItemCount > 0
                    ? mutationLoading
                      ? t('One moment…')
                      : t(
                          '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                          { productCount: validItemCount }
                        )
                    : t('Choose a size to add to cart')}
                </AddToCartButton>
                <ClearList listId={listId}>{t('Clear list')}</ClearList>
              </Summary>
            </Flyout>
          );
        }}
      </DrawerTrigger>
    </>
  );
};

function getItemsForAddToCart(products) {
  return products
    .filter(product => {
      return product.validation.status === 'valid';
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}
function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}
function ClearList({ children, listId }) {
  const { clear } = useProductList(listId);
  return (
    <RemoveButton secondary onClick={clear}>
      {children}
    </RemoveButton>
  );
}

const CartFlyout = props => {
  const topPosition = useHeaderHeight();
  return (
    <DrawerTarget id="favourites-drawer">
      {drawer => (
        <DrawerWrapper topPosition={topPosition}>
          <Drawer isOpen={drawer.isOpen} right>
            <FavouritesFlyoutView modal={drawer} {...props} />
          </Drawer>
        </DrawerWrapper>
      )}
    </DrawerTarget>
  );
};

export default CartFlyout;
