import FlyoutMenu from './CategoryMenu/FlyoutMenu';
import { Above } from '@jetshop/ui/Breakpoints';
import React, { useState, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Notifications } from '../Notifications';
import homeCategoriesQuery from './Categories/HomeCategoriesQuery.gql';
import CampaignBarQuery from './CampaignBarQuery.gql';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import CartFlyout from '../../Cart/CartFlyout';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { allContentComponents } from '../../ContentComponents/ContentComponents';
import FavouritesFlyout from '../../ProductList/FavouritesFlyout';
// import HeaderChannelSelector from './ChannelSelector/ChannelSelector';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import CykelfritidLogo from '../../../images/cykelfritid.jpeg';
import OnlinecykelLogo from '../../../images/Onlinecykel.png';
import XLcykelLogo from '../../../images/XLcykel.jpeg';
import SandnasLogo from '../../../images/Sandnas.png';

export const HeaderCampaignBar = React.memo(() => {
  const { data } = useQuery(CampaignBarQuery, {
    variables: {
      id: 837
    }
  });
  const items = data?.category?.data?.items;

  return (
    <>
      {items && (
        <ContentRenderer items={items} components={allContentComponents} />
      )}
    </>
  );
});

const logoSwitch = selectedChannel => {
  switch (selectedChannel) {
    case 1:
      //Cykelfritid
      return <img src={CykelfritidLogo} alt="Cykelfritid" />;
    //Onlinecykel
    case 2:
      return <img src={OnlinecykelLogo} alt="Onlinecykel" />;
    //XLCykel
    case 3:
      return <img src={XLcykelLogo} alt="XLcykel" />;
    //Sandnas
    case 4:
      return <img src={SandnasLogo} alt="Sandnas" />;

    default:
      return null;
  }
};

export default function Header() {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });
  const [searchOpen, setSearchOpen] = useState(false);
  const { selectedChannel } = useContext(ChannelContext);

  const channelLogo = logoSwitch(selectedChannel?.id);

  return (
    <>
      {/* <div>
        <HeaderChannelSelector />
      </div> */}
      <HeaderCampaignBar />
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <DesktopHeader
                selectedChannel={selectedChannel}
                categories={result}
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
                channelLogo={channelLogo}
              />
            </>
          ) : (
            <>
              <MobileHeader
                selectedChannel={selectedChannel}
                categories={result}
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
                channelLogo={channelLogo}
              />
              <FlyoutMenu
                left={true}
                categoryData={result.data}
                belowHeader={true}
              />
            </>
          )
        }
      </Above>
      <CartFlyout />
      <FavouritesFlyout />
      {/* <ChannelFlyout left={false} /> */}
      <Notifications />
    </>
  );
}
