import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import React, { useState, useEffect, useContext } from 'react';
import { ReactComponent as SearchIcon } from '../../../svg/Search.svg';
import { theme } from '../../Theme';
import IconRow from './IconRow';
import { SearchField } from '@jetshop/ui/Search';
import autocompleteQuery from './AutocompleteQuery.gql';
import { SearchFieldWrapper } from './IconRow';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import { useScrollPosition } from '../../../r3-lib/hooks/useScrollPosition';
import { IconItem } from './IconRow';

const MobileHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;
  height: 71px;
  width: 100%;
  margin-bottom: 40px;

  .search-wrapper {
    width: 100%;
  }
`;

const MobileTopWrapper = styled('div')`
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 2;
  height: 71px;
  min-height: 100%;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.border};

  .wrapper {
    flex: 1;
  }

  .right {
    width: auto;
    display: flex;
    justify-content: flex-end;
    svg {
      color: #333;
    }
  }

  .left {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    svg {
      margin-right: 20px;
    }
  }

  &.Cykelfritid {
    background: #ffe50d;
    .right {
      svg {
        color: #333;
      }
      .badge {
        background: #333;
        color: #fff;
      }
    }
  }
  &.Onlinecykel {
    background: #000;
    .right {
      svg {
        color: #fff;
      }
      .badge {
        background: #fff;
        color: #000;
      }
    }
  }
  &.XLCykel {
    background: #fd0;
  }
  &.Sandnas {
    background: #343434;
    .right {
      svg {
        color: #fff;
        background: #343434;
      }
      .badge {
        background: #e0b771;
        color: #343434;
      }
    }
  }
`;

const LogoLink = styled(Link)`
  img,
  svg {
    width: 177px;
    height: auto;
  }
`;

export default function MobileHeader({
  searchOpen,
  setSearchOpen,
  selectedChannel,
  channelLogo
}) {
  const { modals } = useContext(ModalContext);

  const scrollPosition = useScrollPosition();

  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (scrollPosition) {
      setSearchOpen(false);
    }
  }, [scrollPosition]);

  setTimeout(() => {
    setShowSearch(true);
  }, 1000);

  return (
    <>
      <MobileHeaderWrapper id="site-header">
        <MobileTopWrapper className={cx(selectedChannel?.name)}>
          <div className="left">
            <LogoLink to="/" className="logo-link">
              {channelLogo}
            </LogoLink>
          </div>
          <div className="right">
            <IconItem>
              <button
                data-testid="search-button"
                id="search-button"
                onClick={() => {
                  modals && modals[0]?.hideTarget();
                  setSearchOpen(!searchOpen);
                }}
              >
                <SearchIcon />
              </button>
            </IconItem>
            <IconRow searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
          </div>
        </MobileTopWrapper>
        <div className="search-wrapper">
          {showSearch && (
            <SearchFieldWrapper
              className={cx(
                scrollPosition < 200 && 'active',
                scrollPosition > 201 && 'inactive',
                searchOpen && 'search-active'
              )}
            >
              <SearchIcon
                className="search-icon"
                onClick={() => {
                  modals && modals[0]?.hideTarget();
                  setSearchOpen(!searchOpen);
                }}
              />
              <SearchField
                onCancel={() => null}
                onSubmit={() => setSearchOpen(false)}
                focusOnLoad={false}
                placeholder="Sök produkt"
                autocompleteQuery={autocompleteQuery}
              />
            </SearchFieldWrapper>
          )}
        </div>
      </MobileHeaderWrapper>
    </>
  );
}
