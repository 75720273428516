import React, { useContext, createContext } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { ReactComponent as Caret } from '../../svg/Caret.svg';
import { theme } from '../Theme';
import { StructuredData } from '@jetshop/core/components/StructuredData';

const AccordionFieldWrapper = styled('div')`
  max-width: 700px;
  width: 100%;
  /* padding: 0 20px; */
  margin: auto;
  h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.03em;
    margin-bottom: 10px;
  }
  h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #000;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #787878;
  }
  a {
    text-decoration: underline;
    color: #000;
  }
  .accordion-content {
    margin: 10px 0 20px 0;
  }
  section {
    border-top: 1px solid #e4e4e4;
  }
  section:last-of-type {
    border-bottom: 1px solid #e4e4e4;
  }

  /* ${theme.below.lg} {
    padding: 0 18px;
  } */
`;

const StyledCaret = styled(Caret)`
  align-self: center;
  color: #787878;
  transition: all 0.2s ease-in-out;

  &.open {
    transform: rotate(-90deg);
  }
  &.closed {
    transform: rotate(0deg);
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 1.25rem;
  display: flex;
`;

const AccordionTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0 !important;
`;

export const FAQ = ({ children, title }) => {
  const faqData = [];
  const getStructuredDataFromFaq = children => {
    children.forEach(child => {
      faqData.push({
        '@type': 'Question',
        name: child?.props?.question?.value,
        acceptedAnswer: {
          '@type': 'Answer',
          text: child?.props?.answer?.value
        }
      });
    });
  };

  getStructuredDataFromFaq(children);

  return (
    <>
      <StructuredData
        json={[
          {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: faqData
          }
        ]}
      />

      <AccordionFieldWrapper>
        <h3>{title?.value}</h3>
        <Accordion single>
          {({ openIndexes, handleClick, AccordionContent }) => (
            <>
              {children.map((child, index) => (
                <AccordionContext.Provider
                  key={index}
                  value={{
                    index: index,
                    openIndexes: openIndexes ?? 0,
                    handleClick: handleClick,
                    accordionContent: AccordionContent
                  }}
                >
                  {child}
                </AccordionContext.Provider>
              ))}
            </>
          )}
        </Accordion>
      </AccordionFieldWrapper>
    </>
  );
};

export const AccordionContext = createContext();

export const FAQItem = ({ answer, question }) => {
  const accordionContext = useContext(AccordionContext);
  const AccordionIndicator = ({ isOpen }) => (
    <IndicatorIcon>
      {<StyledCaret className={cx(!isOpen ? 'open' : 'closed')} />}
    </IndicatorIcon>
  );

  return (
    <section key={accordionContext.index}>
      <AccordionTitle
        onClick={() => accordionContext.handleClick(accordionContext.index)}
      >
        <h4>{question?.value}</h4>
        <AccordionIndicator
          isOpen={accordionContext.openIndexes.includes(accordionContext.index)}
        />
      </AccordionTitle>

      <accordionContext.accordionContent
        isOpen={accordionContext.openIndexes.includes(accordionContext.index)}
      >
        <div
          className="accordion-content"
          dangerouslySetInnerHTML={{
            __html: answer?.value
          }}
        />
      </accordionContext.accordionContent>
    </section>
  );
};
