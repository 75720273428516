import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import useHeaderHeight from '../../r3-lib/hooks/useHeaderHeight';
import { uspComponents } from '../ContentComponents/ContentComponents';
import { theme } from '../Theme';
import { baseStyles } from '../ui/Button';
import CartItem from './CartItem';
import cartQuery from './queries/cartQuery.gql';
import DynamicCategoryRenderer from '../../r3-lib/components/layout/DynamicCategoryRenderer/DynamicCategoryRenderer';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { cx } from 'linaria';

const DrawerWrapper = styled('div')`
  // Flyout
  > div {
    background: #f7f7f7;
    top: ${props => props.topPosition}px;
    border-top: 1px solid ${theme.colors.border};
  }
`;

const Flyout = styled('div')`
  background: #f7f7f7;
  padding-bottom: 130px;
  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    width: 22rem;
    min-width: 19rem;
    max-width: 100%;
  }

  .empty-title {
    font-family: ${theme.fonts.primary};
    font-weight: 600;
    text-align: left;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 20px;
    + p {
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      line-height: 22px;
      text-align: left;
    }
  }
`;

const CartItems = styled('section')`
  background: white;
  padding: 0 20px;
  .cart-item {
    border-top: 1px solid ${theme.colors.border};
  }
  .cart-item:first-of-type {
    border: none;
  }
`;

const Summary = styled('section')`
  padding: 30px 20px 150px 20px;

  .cart-total {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 18px;
    label {
      margin-right: 3px;
    }
  }
`;

const Checkout = styled.a`
  ${baseStyles};
  width: 100%;
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  text-decoration: none;
  background: ${theme.colors.secondary};
  color: ${theme.colors.blackish};
  border-radius: ${theme.borderRadius};
  border: 0;
  font-family: ${theme.fonts.primary};
  font-weight: 500;

  :hover {
    opacity: 0.9;
  }

  &.Onlinecykel {
    background: #000;
    color: #fff;
  }
  &.XLCykel {
    background: #fd0;
    color: ${theme.colors.blackish};
  }
  &.Sandnas {
    background: #e0b771;
    color: ${theme.colors.blackish};
  }
`;

const KeepShopping = styled.button`
  ${baseStyles};
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  appearance: none;
  background: ${theme.colors.blackish};
  color: white;
  border-radius: ${theme.borderRadius};
  border: 0;
  font-family: ${theme.fonts.primary};
  font-weight: 500;

  :hover {
    opacity: 0.9;
  }
`;

const UspWrapper = styled('div')``;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = result?.data?.cart?.items ?? [];
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const track = useTracker();

  const { selectedChannel } = useContext(ChannelContext);

  const findCartUspId = () => {
    switch (selectedChannel?.id) {
      //Cykel&Fritid
      case 1:
        return 839;
      //OnlineCykel
      case 2:
        return 849;
      //XLCykel
      case 3:
        return 850;
      //Sandnas
      case 4:
        return 851;
      //default
      default:
        return 839;
    }
  };

  const cartUspId = findCartUspId();

  if (items?.length === 0) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em', background: 'white' }}>
          <h2 className="empty-title">{t('Cart')}</h2>
          <p>{t('There are no products in your cart.')}</p>
        </div>
        <Summary>
          <KeepShopping onClick={modal.hideTarget}>
            {t('Continue Shopping')}
          </KeepShopping>
        </Summary>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} />
        ))}
      </CartItems>
      <Summary>
        <div className="cart-total">
          <label>{t('Total')}:</label>
          <Price price={result.data.cart.productTotal} />
        </div>

        {checkoutUrl && (
          <Checkout
            className={cx(selectedChannel?.name)}
            data-testid="checkout-button"
            href={checkoutUrl}
            onClick={event => {
              event.preventDefault();
              track(
                trackCartCheckoutEvent({
                  cart: result.data.cart,
                  callback: () => {
                    window.location = checkoutUrl;
                  }
                })
              );
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
        <KeepShopping onClick={modal.hideTarget}>
          {t('Continue Shopping')}
        </KeepShopping>

        <UspWrapper>
          <DynamicCategoryRenderer
            categoryId={cartUspId}
            rendererComponents={uspComponents}
          />
        </UspWrapper>
      </Summary>
    </Flyout>
  );
};

const CartFlyout = props => {
  const topPosition = useHeaderHeight();
  return (
    <CartProvider query={cartQuery}>
      {result =>
        result.data && result.data.cart ? (
          <DrawerTarget id="cart-drawer">
            {drawer => (
              <DrawerWrapper topPosition={topPosition}>
                <Drawer isOpen={drawer.isOpen} right>
                  <CartFlyoutView modal={drawer} result={result} {...props} />
                </Drawer>
              </DrawerWrapper>
            )}
          </DrawerTarget>
        ) : null
      }
    </CartProvider>
  );
};

export default CartFlyout;
