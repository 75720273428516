import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../../../components/Theme';

const BoxRowWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 71rem;
  margin: auto;

  &.fullWidth {
    max-width: 100%;
  }
  &.padding {
    padding: 0 40px;
    ${theme.below.lg} {
      padding: 0 20px;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 25px;
    line-height: 34px;
    font-weight: 700;
  }
`;

const ItemsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;
  grid-gap: ${props => props.gap}px;

  .item {
    width: calc(100% / ${props => props.items});
  }

  ${theme.below.lg} {
    flex-direction: column;
    .item {
      width: 100%;
    }
    .content-placer {
      position: absolute;
    }
  }
`;

export const BoxRow = ({ title, fullWidth, gap, padding, children }) => {
  return (
    <BoxRowWrapper
      className={cx(
        fullWidth?.value && 'fullWidth',
        padding?.value && 'padding'
      )}
    >
      {title?.value && <h2 className="title">{title?.value}</h2>}
      <ItemsWrapper gap={gap?.value ?? 0} items={children?.length}>
        {children}
      </ItemsWrapper>
    </BoxRowWrapper>
  );
};
