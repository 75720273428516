import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../../components/Theme';

const TextColumnItem = styled('div')`
  width: 100%;
  color: ${theme.colors.white};
  font-family: ${theme.fonts.primary};
  font-size: 14px;
  line-height: 22px;
  margin-top: 4px;
`;

export const FooterColumnTextItem = ({ html }) => {
  return (
    <div>
      <TextColumnItem
        dangerouslySetInnerHTML={{
          __html: html?.value
        }}
      />
    </div>
  );
};
